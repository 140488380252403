import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../components/layout"
import BgImage from '../components/bg-image'

const Story = () => (
  <Layout>
    <Helmet title="Our Story" />
    <BgImage image="/assets/our-story.jpg" className="top">
      <h1>Our Story</h1>
    </BgImage>
    <section className="content">
      <h1>What makes Sydney Pool Renovations unique?</h1>
      <p>
        Our founder and director, Errol Samuel, is a qualified engineer and
        long-term member of the water industry. This rare combination enables
        him to solve complex problems to the highest levels as well as being
        able to foresee construction issues before they happen. Errol works
        closely with his team to make sure they are aware of best practices for
        construction. He and his team work closely with materials suppliers to
        make sure they use high quality materials.
      </p>
      <p>
        <strong>We provide expertise in</strong>
      </p>
      <ul>
        <li>Paving and outdoor tiling.</li>
        <li>Outdoor decking and carpentry.</li>
        <li>Applying waterproof membranes.</li>
        <li>Laying waterline tiles and fully tiling pools.</li>
        <li>Resurfacing interiors of pools.</li>
        <li>Eco equipment to reduce your carbon footprint.</li>
        <li>Core drilling and running pipe lines.</li>
        <li>Fixing rust inside pools.</li>
        <li>Building water features.</li>
        <li>Modifying the shape of pools.</li>
      </ul>
      <p>
        Sydney Pool Renovations believe that renovating and remodelling
        outstanding pools is about integrating the knowledge, experience,
        imagination and activity of its team members to get the most out of each
        other.
      </p>
    </section>
  </Layout>
)

export default Story
